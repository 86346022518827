<template>
  <v-container fluid>
    <v-card flat class="py-2 px-4">
      <v-form
        ref="form"
        v-model="valid"
        class="ml-sm-6"
        lazy-validation
      >
        <v-row>
          <v-col cols="12" sm="2" lg="1">
            <v-btn
              color="primary"
              depressed
              class="px-8 mb-5"
              @click="sendEmail"
            >
              <v-icon class="mr-2">mdi-send</v-icon>
              Send
            </v-btn>
            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  outlined
                  depressed
                  @click="showTemplateDialog = true"
                >
                  <v-icon>mdi-stamper</v-icon>
                  Template
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="10" lg="11">
            <v-combobox
              v-model="recipientData"
              placeholder="Recipient"
              label="Recipient"
              class="ml-sm-16"
              :items="recipientItems"
              item-text="fullName"
              item-value="email"
              :rules="[v => !!v || 'This field is required']"
              outlined
              multiple
              clearable
              counter
              deletable-chips
              dense
              chips
            >
              <template v-slot:selection="data">
                <div
                  :key="JSON.stringify(data.item)"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  :disabled="data.disabled"
                  class="mr-2"
                  @click:close="data.parent.selectItem(data.item)"
                >
                  <v-avatar
                    class="primary white--text"
                    size="25"
                    left
                    v-text="data.item.fullName.slice(0, 1).toUpperCase()"
                  />
                  {{ data.item.fullName }},
                </div>
              </template>
            </v-combobox>
            <v-text-field
              v-model="object"
              class="ml-sm-16"
              placeholder="Subject"
              label="Subject"
              :rules="[v => !!v || 'This field is required']"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="editorData"
              :rules="[v => !!v || 'This field is required']"
              clearable
              height="400"
              outlined
              counter
              autocomplete="email"
              no-resize
              dense
            />
          <!--<editor
            v-model="editorData"
            api-key=""
            :init="{
              plugins: 'table link lists image wordcount template code',
              toolbar: 'undo redo | styles fontfamily fontsize | bold italic underline | forecolor backcolor | bullist numlist | alignleft aligncenter alignright | outdent indent | image link',
              statusbar: false,
              height: 580,
              content_css: colorTheme,
              link_default_target: '_blank',
              object_resizing: false,
              images_file_types: 'jpg,jpeg,svg,png,webp',
              automatic_uploads: true,
              file_picker_types: 'image',
              file_picker_callback: (cb, value, meta) => {
                const input = document.createElement('input')
                input.setAttribute('type', 'file')
                input.setAttribute('accept', 'image/*')
                input.addEventListener('change', (e) => {
                  const file = e.target.files[0]
                  const reader = new FileReader()
                  reader.addEventListener('load', () => {
                    const id = 'blobid' + (new Date()).getTime()
                    const blobCache = tinymce.activeEditor.editorUpload.blobCache
                    const base64 = reader.result.split(',')[1]
                    const blobInfo = blobCache.create(id, file, base64)
                    blobCache.add(blobInfo)
                    cb(blobInfo.blobUri(), { title: file.name })
                  })
                  reader.readAsDataURL(file)
                })

                input.click()
              }
            }"
          />-->
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <base-dialog
      :show="showTemplateDialog"
      form-title="Select Template"
      size="26%"
      icon="mdi-stamper"
      text-btn2="Apply"
      v-on:close="close"
      v-on:save="save"
    >
      <template v-slot:form>
        <v-form
          ref="formTemplate"
          v-model="valid2"
          lazy-validation
        >
          <v-list-item-content class="text-left my-0">
            <div class="text-left primary--text text--lighten-2 font-weight-bold">TEMPLATE</div>
          </v-list-item-content>
          <v-select
            v-model="tempSelec"
            :items="templateItems"
            outlined
            dense
            item-text="name"
            item-value="id"
          />
        </v-form>
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import BaseDialog from '@/components/dialog/BaseDialog'
// import { emailEmptyEn } from '@/data/emailTemplates/emailEmptyEn.js'
// import { emailEmptyFr } from '@/data/emailTemplates/emailEmptyFr.js'
import PentestService from '@/services/pentest.service'
import UserService from '@/services/user.service.js'
import CommunicationsService from '@/services/communications.service.js'
import TemplateService from '@/services/template.service.js'
import snackbarPlugin from '@/plugins/snackbar'

import logoVumetricWhite from '@/assets/images/logos/vumetric-cybersecurity_dark.png'
import logoFacebook from '@/assets/images/logos/facebook.png'
import logoTwitter from '@/assets/images/logos/twitter_Logo.png'
import logoLinkedIn from '@/assets/images/logos/linkedin.png'
import logoVumetricDark from '@/assets/images/logos/logo_vumetric_light.png'

// import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'SendEmail',
  metaInfo: {
    title: 'Send Email'
  },
  components: {
    BaseDialog
    // editor: Editor,
  },
  data() {
    return {
      showTemplateDialog: false,
      editorData: null,
      valid: true,
      valid2: true,
      templateItems: null,
      tempSelec: null,
      titleTemplate: '',
      // subTitleTemplate: '',
      messageTemplate: '',
      // subMessageTemplate: '',

      colorTheme: '',
      pentestData: {},
      recipientItems: [],
      recipientData: null,
      recipient: [],
      object: 'Vumetric - ',
      message: null,
      msgEmail: null,
      // projectId: null,
      language: 'en',

      logoVumetricWhite,
      logoFacebook,
      logoTwitter,
      logoLinkedIn,
      logoVumetricDark

      // emailEmptyEn,
      // emailEmptyFr,
    }
  },
  created() {
    this.getRecipient()
  },
  mounted() {
    if (this.$vuetify.theme.dark) {
      this.colorTheme = 'dark'
    }
    this.$store.dispatch('changeThemeLoadingState', false)
    this.initializeTemplateData()
  },
  methods: {
    close() {
      this.showTemplateDialog = false
    },
    save() {
      if (this.$refs.formTemplate.validate()) {
        this.setTemplateData()
        this.close()
      }
      if (!this.$refs.formTemplate.validate()) {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    },
    async initializeData(id) {
      const response = await PentestService.getByIdPentests(id)
      // if (res.status === 200) {
      //   this.pentestData = res.data
      //   this.object = 'Vumetric - ' + this.pentestData.title
      //   // this.projectId = this.pentestData.id
      //   this.initializeUsersData(this.pentestData.client.id)
      // }
      if (response) {
        this.pentestData = response
        this.object = 'Vumetric - ' + this.pentestData.title
        // this.projectId = this.pentestData.id
        await this.initializeUsersData(this.pentestData.client.id)
      }
    },
    async initializeTemplateData() {
      const res = await TemplateService.getTemplate()
      this.templateItems = res.results
    },
    async setTemplateData() {
      const res = await TemplateService.getTemplateById(this.tempSelec)
      this.titleTemplate = res.title
      this.editorData = res.message
      this.language = res.language

      // this.subTitleTemplate = res.subTitle
      // this.subMessageTemplate = res.subMessage
      // if (res.language === 'fr') {
      //   this.editorData = this.emailEmptyFr.template
      // }
      // if (res.language === 'en') {
      //   this.editorData = this.emailEmptyEn.template
      // }
      // if (this.editorData !== null) {
      //   const content = this.editorData
      //   this.editorData = `${content.replace('{$titleEmpty}', res.title).replace('{$subtitleEmpty}', res.subTitle).replace('{$messageEmpty}', res.message).replace('{$subMessageEmpty}', res.subMessage)}`
      // }
    },
    async initializeUsersData(clientID) {
      const res = await UserService.getUsers(1, 100, null, { clientID: clientID })
      this.recipientItems = res.results
      if (this.$route.query.pentest) {
        this.recipientData = this.recipientItems
      }
    },
    getRecipient() {
      if (this.$route.query.pentest && this.$route.query.pentest.length === 36) {
        this.initializeData(this.$route.query.pentest)
        this.object = 'Vumetric - ' + this.pentestData.title
      } else {
        this.initializeUsersData()
      }
    },
    convertEmail() {
      const data = this.recipientData
      const emailArray = []
      for (let i = 0; i < data.length; i++) {
        emailArray.push({ email: data[i].email })
      }
      this.recipient = emailArray
    },
    convertMessage() {
      if (this.editorData !== null) {
        const msg = this.editorData
        this.msgEmail = `${msg.replace('<p>&nbsp;', '').replace('<p>&nbsp;</p>', '')}`
      }
    },
    async sendEmail() {
      if (this.$refs.form.validate() && this.editorData !== null) {
        snackbarPlugin.showLoading('Sending ...')
        this.convertEmail()
        this.convertMessage()
        const res = await CommunicationsService.postEmail({
          recipients: this.recipient,
          object: this.object,
          title: this.titleTemplate,
          language: this.language,
          message: this.msgEmail
          // projectId: this.projectId,
        })
        if (res !== undefined) {
          snackbarPlugin.showSuccess('Email was Send')
          setTimeout(() => {
            if (this.$route.query.pentest && this.$route.query.pentest.length === 36) {
              this.$router.push(`/pentest/detail/${this.$route.query.pentest}`)
            } else {
              this.$router.push('/templates')
            }
          }, 3000)
        }
      }
      if (!this.$refs.form.validate() || this.editorData === null) {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    }
  }
}
</script>
